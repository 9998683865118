// Generated by purs version 0.13.8
"use strict";
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var ZAP_Dart_Client_BuildConfig = require("../ZAP.Dart.Client.BuildConfig/index.js");
var success = React_Basic_Native_PropTypes_Color.rgb(131)(190)(119);
var separator = React_Basic_Native_PropTypes_Color.rgb(213)(213)(213);
var secondaryLink = React_Basic_Native_PropTypes_Color.rgb(83)(193)(194);
var secondary = ZAP_Dart_Client_BuildConfig.buildConfig.color.secondary;
var primaryLink = React_Basic_Native_PropTypes_Color.rgb(120)(245)(245);
var primary = ZAP_Dart_Client_BuildConfig.buildConfig.color.primary;
var lightPrimary = ZAP_Dart_Client_BuildConfig.buildConfig.color.lightPrimary;
var lightIcon = React_Basic_Native_PropTypes_Color.rgb(137)(137)(137);
var fgMuted = React_Basic_Native_PropTypes_Color.rgb(131)(131)(131);
var fg = React_Basic_Native_PropTypes_Color.rgb(46)(46)(46);
var error = React_Basic_Native_PropTypes_Color.rgb(174)(65)(65);
var divider = React_Basic_Native_PropTypes_Color.rgb(196)(196)(196);
var darkPrimary = ZAP_Dart_Client_BuildConfig.buildConfig.color.darkPrimary;
var bg = React_Basic_Native_PropTypes_Color.rgb(255)(255)(255);
var altHighlight = React_Basic_Native_PropTypes_Color.rgb(245)(245)(245);
var altFg = React_Basic_Native_PropTypes_Color.rgb(33)(33)(33);
var altBg = React_Basic_Native_PropTypes_Color.rgb(248)(248)(248);
var accent = ZAP_Dart_Client_BuildConfig.buildConfig.color.accent;
module.exports = {
    primary: primary,
    lightPrimary: lightPrimary,
    darkPrimary: darkPrimary,
    secondary: secondary,
    accent: accent,
    fg: fg,
    fgMuted: fgMuted,
    bg: bg,
    altFg: altFg,
    altBg: altBg,
    primaryLink: primaryLink,
    secondaryLink: secondaryLink,
    success: success,
    error: error,
    separator: separator,
    divider: divider,
    lightIcon: lightIcon,
    altHighlight: altHighlight
};
