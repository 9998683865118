import Constants from 'expo-constants';

export const apiUrl = {
  dev: 'http://localhost:3001',
  staging: 'https://api.bottleco.staging.zap.com.ph',
  preprod: 'https://api.bottleco.preprod.zap.com.ph',
  prod: 'https://api.bottleco.zap.com.ph',
};

const aboutUrl = {
  dev: 'https://about.bottleco.zap.com.ph',
  staging: 'https://about.bottleco.zap.com.ph',
  preprod: 'https://about.bottleco.zap.com.ph',
  prod: 'https://about.bottleco.zap.com.ph',
};

export const getApiUrl = (zenv = Constants.manifest.releaseChannel) => {
  if (__DEV__) return apiUrl.dev;
  if (zenv === 'staging') return apiUrl.staging;
  if (zenv === 'preprod') return apiUrl.preprod;
  if (zenv === 'prod') return apiUrl.prod;
};

export const getAboutUrl = (zenv = Constants.manifest.releaseChannel) => {
  if (__DEV__) return aboutUrl.dev;
  if (zenv === 'staging') return aboutUrl.staging;
  if (zenv === 'preprod') return aboutUrl.preprod;
  if (zenv === 'prod') return aboutUrl.prod;
};
