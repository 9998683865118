// Generated by purs version 0.13.8
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var ReactNative_Touchables = require("../ReactNative.Touchables/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Components_NavBar_BaseNavBar = require("../ZAP.Dart.Client.Components.NavBar.BaseNavBar/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var sheet = {
    cartButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.right(React_Basic_Native_Styles.intRight)(10) ])
};
var cartMgtNavBar = (function () {
    var right = function (props) {
        return React_Basic_Native_Generated.touchableOpacity()({
            style: sheet.cartButton,
            onPress: React_Basic_Events.handler_(props.onAction(new ReactNavigation_Types.NavBarSubmit(new Data_Maybe.Just(ZAP_Dart_Client_Screens_Types.ManageCart.value)))),
            hitSlop: ReactNative_Touchables.insets({
                top: 10,
                bottom: 10,
                left: 10,
                right: 10
            }),
            children: [ ZAP_Dart_Client_Components_Icon.icon({
                name: "cart_outlined",
                size: 36,
                color: ZAP_Dart_Client_Styles_Colors.primary
            }) ]
        });
    };
    return ZAP_Dart_Client_Components_NavBar_BaseNavBar.baseNavBar({
        right: right,
        persistBack: false
    });
})();
module.exports = {
    cartMgtNavBar: cartMgtNavBar
};
