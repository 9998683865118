// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var swiper = function (dictUnion) {
    return React_Basic.element($foreign["_swiper"]);
};
module.exports = {
    swiper: swiper,
    "_swiper": $foreign["_swiper"]
};
